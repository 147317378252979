

































































import { Component, Vue } from 'vue-property-decorator';
import AllianzWithdrawalModerateViewModel
  from '@/vue-app/view-models/allianz-dashboard/withdrawals/allianz-withdrawal-moderate-view-model';

@Component({ name: 'AllianzWithdrawalModerate' })
export default class AllianzWithdrawalModerate extends Vue {
  withdrawal_moderate_view_model = Vue.observable(
    new AllianzWithdrawalModerateViewModel(this),
  );
}


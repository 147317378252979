import Vue from 'vue';
import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzWithdrawalModerateViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.withdrawals.withdrawal_moderate';

  readonly view: Vue;

  confirm_moderate = false;

  is_disabled = true;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  constructor(view: Vue) {
    this.view = view;
  }

  confirmModerate = () => {
    this.is_disabled = !this.confirm_moderate;
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }
}
